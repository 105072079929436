import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/mue-kon/logo.png';
import img1 from '../../images/projects/mue-kon/img1.jpg';
import img12x from '../../images/projects/mue-kon/img1@2x.jpg';
import img2 from '../../images/projects/mue-kon/img2.jpg';
import img22x from '../../images/projects/mue-kon/img2@2x.jpg';
import img3 from '../../images/projects/mue-kon/MKGrafik.png';
import img4 from '../../images/projects/mue-kon/img4.jpg';
import img42x from '../../images/projects/mue-kon/img4@2x.jpg';

const title = 'MUE KON Konstruktionsbüro';
const text =
	'Das symmetrisch gestaltete Logo mit einem 3D-Symbol in der Mitte vereint auf elegante Weise die Kompetenzen und Qualitäten des Konstruktionsbüros „MUE KON“: Orientierung am Zeitgeist, Präzision und Klarheit stehen hier im Fokus. Screendesign der Webseite ebenso wie die Geschäftsausstattung sind wesentliche Bestandteile der Außendarstellung.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="logo" className="mue-kon" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} src2={img42x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
